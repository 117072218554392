// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-troiscouleurs-js": () => import("./../../../src/templates/category_troiscouleurs.js" /* webpackChunkName: "component---src-templates-category-troiscouleurs-js" */),
  "component---src-templates-error-js": () => import("./../../../src/templates/error.js" /* webpackChunkName: "component---src-templates-error-js" */),
  "component---src-templates-search-troiscouleurs-js": () => import("./../../../src/templates/search_troiscouleurs.js" /* webpackChunkName: "component---src-templates-search-troiscouleurs-js" */),
  "component---src-templates-trois-couleurs-js": () => import("./../../../src/templates/trois_couleurs.js" /* webpackChunkName: "component---src-templates-trois-couleurs-js" */)
}

